import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { RiHomeLine } from "react-icons/ri";
import { SiTwitter, SiGithub, SiLinkedin, SiSubstack } from "react-icons/si";


// Import your image from the images folder
import overlayImage from "../images/me.png"

// Global styles
const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Raleway', sans-serif;
    background-color: #f3efee; /* Fallback for older browsers */
    overflow: hidden; /* Prevent scrolling */
    padding: 0;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; // Changed to min-height for full height
  }
`

// Container for the entire content
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
  min-height: 100vh;
`

// MainContent for the main section
const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
  padding-top: 4rem; /* To ensure the content does not overlap with the fixed header */
`

// Header styled component
const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f3efee; /* Match background color */
  position: fixed;
  top: 0;
  z-index: 1000; /* Ensure it stays on top of other elements */
  left: 0;
`

const NavLinks = styled.div`
  display: flex;
  align-items: center;
`

const NavLink = styled.a`
  color: #333;
  text-decoration: none;
  margin-left: 1rem;
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #555;
  }

  &:first-child {
    margin-left: 0; /* Remove left margin for the first child */
  }
`

// Left section for the image
const LeftSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

// Image styled component
const Image = styled.img`
  max-width: 100%;
  height: auto;
`

// Right section for heading and social icons
const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Padding inside the section */
`

// Heading styled component
const Heading = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-size: 3rem;
  color: #333;
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem; /* Decrease font size on smaller screens */
  }
`

// Social icons container
const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

// Individual social icon styled component
const IconWrapper = styled.div`
  font-size: 2rem;
  color: #555;
  margin: 0 10px;
  transition: color 0.3s ease;

  &:hover {
    color: #333;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Decrease font size on smaller screens */
  }
`

// Anchor tag for each social icon
const SocialLink = styled.a`
  color: inherit;
  text-decoration: none;
`

// Footer styled components
const Footer = styled.footer`
  bottom: 0;
  width: 100%;
  background-color: #f3efee; /* Match background color with the rest of the page */
  color: #333;
  text-align: center;
  padding: 10px 0;
`

const HeartIcon = styled.span`
  color: #ff4f78; /* Heart icon color */
`

// Made with Love styled component
const MadeWithLove = styled.p`
  margin: 5px 0 0;
  font-size: 0.8rem;
`

// Main component
const IndexPage = () => (
  <Container>
    <GlobalStyle />
    <MainContent>
      <LeftSection>
        <Image src={overlayImage} alt="Me" />
      </LeftSection>
      <RightSection>
        <Heading>
          Abhishek Singh
        </Heading>
        <SocialIcons>
          <IconWrapper>
            <SocialLink href="https://x.com/databhishek">
              <SiTwitter />
            </SocialLink>
          </IconWrapper>
          <IconWrapper>
            <SocialLink href="https://github.com/databhishek">
              <SiGithub />
            </SocialLink>
          </IconWrapper>
          <IconWrapper>
            <SocialLink href="https://linkedin.com/in/databhishek">
              <SiLinkedin />
            </SocialLink>
          </IconWrapper>
          <IconWrapper>
            <SocialLink href="https://substack.com/@databhishek">
              <SiSubstack />
            </SocialLink>
          </IconWrapper>
        </SocialIcons>
      </RightSection>
    </MainContent>
    <Footer>
      <MadeWithLove>
        Made with <HeartIcon>&hearts;</HeartIcon> in Bangalore, circa 2024
      </MadeWithLove>
    </Footer>
  </Container>
)

export default IndexPage
